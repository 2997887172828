const _ = require('lodash');

//GLOBALS
var stationListener, logsListener, usersListener, lastLog;
let db = '';
const userRoles = ['user', 'staff', 'admin', 'guest'];

//METHODS
export async function getStations(firestore, userStationsIds) {
    let stations = [];
    try {
        await firestore.collection("stations").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (userStationsIds.includes(doc.id) || userStationsIds.includes('ALL'))
                    stations.push(data);
            });
        });
    }
    catch (e) { }

    return stations;
}

export async function initStations(firestore, callbackDB, stationIds) {
    db = firestore;
    try {
        if (stationListener)
            stationListener();

        if (stationIds?.length === 0)
            return;

        if (stationIds?.length < 10) // array-contains, in etc.. tenen una limitació de comparacio amb array de longitud maxima 19
            stationListener = await db.collection('stations').where('id', 'in', stationIds)
                .onSnapshot(function (snapshot) {
                    callbackDB(snapshot.docs);
                });
        else
            stationListener = await db.collection('stations')
                .onSnapshot(function (snapshot) {
                    callbackDB(snapshot.docs);
                });
    }
    catch (e) { console.log("init error: " + e); }
}

export async function getLogs(firestore, stationIds = [], from = new Date().getTime() - (24 * 60 * 60 * 1000), to = new Date().getTime()) {
    db = firestore;
    try {
        let logs = []; let it = 0;
        for (const station of stationIds) {
            await db.collection("logs").doc(station).collection("LOGS")
                .orderBy('time', 'desc')
                .where('time', '<', to)
                .where('time', '>', from)
                .limit(parseInt((10000 - logs.length) / (stationIds.length - it)))
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.stationId = station;
                        logs.push(data);
                    });
                });
            it++;
        };
        return logs;
    }
    catch (e) { console.log("getLogs error: " + e); }
}

export async function initUsers(firestore, callbackDB, stationIds = []) {
    db = firestore;
    let myUsers = [];
    try {
        if (usersListener)
            usersListener();

        usersListener = await db.collection('users').where('stations', '!=', null)
            .onSnapshot(function (snapshot) {

                let myUsersTmp = [];
                snapshot.docs.forEach(userTmp => {

                    userTmp = userTmp.data();

                    if (userTmp.id && userTmp.stations?.some(st => st?.ids?.some(item => stationIds.includes(item))))
                        myUsersTmp.push({
                            id: userTmp.id,
                            role: userTmp.role,
                            stations: userTmp.stations.filter(s => s.type === 'stations'),
                            name: userTmp.name,
                            keyCode: userTmp.keyCode,
                            email: userTmp.email,
                            pin: userTmp.pin,
                            deposits: userTmp.deposits,
                            status: userTmp.status?.state ?? userTmp.status,
                            time: userTmp.time,
                            registered: userTmp.registered
                        });
                });

                if (!_.isEqual(myUsers, myUsersTmp)) {
                    myUsers = myUsersTmp;
                    callbackDB(myUsers);
                }
            });
    }
    catch (e) { console.log("init error: " + e); }
}

export async function getUsers(firestore, stationIds = []) {
    db = firestore;
    try {
        let myUsersTmp = [];
        await db.collection("users")
            .where('stations', '!=', null)
            //.limit(10)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const userTmp = doc.data();
                    if (userTmp.id && userTmp.stations?.some(st => st?.ids?.some(item => stationIds.includes(item))))
                        myUsersTmp.push({
                            id: userTmp.id,
                            role: userTmp.role,
                            stations: userTmp.stations?.filter(s => s.type === 'stations'),
                            name: userTmp.name,
                            keyCode: userTmp.keyCode,
                            email: userTmp.email,
                            pin: userTmp.pin,
                            deposits: userTmp.deposits,
                            status: userTmp.status?.state ?? userTmp.status,
                            time: userTmp.time,
                            registered: userTmp.registered
                        });
                });
            });
        return myUsersTmp;
    }
    catch (e) { console.log("getLogs error: " + e); }
}

export async function getStation(firestore, id) {
    try {
        var docRef = await firestore.collection("stations").doc(id);
        var res = await docRef.get();
        return await res.data();
    }
    catch (e) { }
}

export async function openLock(firestore, lock) {
    try {
        const docRef = await firestore.collection("stations").doc(lock.stationId);
        var data = await docRef.get()
        data = data.data();
        data.action = 'update';

        data.LOCKS.filter(item => {
            if (item.num === lock.num && !item.isOpen) {
                item.isOpen = true;
            }
        });

        data.time = new Date().getTime();
        let resp = await docRef.set(data, { merge: true })
        return resp;
    }
    catch (e) {
        console.error(`<setLock> error: ${e}`);
    }
}